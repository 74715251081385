@tailwind base;
@tailwind components;
@tailwind utilities;

.aa-Panel {
	z-index: 9;
}
:root {
	--swiper-theme-color: #d97706 !important;
}
.swiper {
	margin-top: 0px;
	width: 100%;
	height: calc(100vh - 64px);
  }
  
  .swiper-slide {
	text-align: center;
	font-size: 18px;
	/* background: #ccc; */

	display: flex;
	justify-content: center;
	align-items: center;

	background-size: cover;
  	background-position: center;

  }
  
  .swiper-slide img {
	display: block;
  	width: 100%;
  	height: 100%;
	/* height: 450px; */
  }
  
  @keyframes spinner {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }